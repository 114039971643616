



























































































import { Vue, Component, Watch } from 'vue-property-decorator'
import {
    ITransactionData,
    ITransactionDataProcessed,
    TransactionType,
} from '@/store/modules/history/types'
import moment from 'moment'

import TxRow from '@/components/wallet/activity/TxRow.vue'
import RadioButtons from '@/components/misc/RadioButtons.vue'
import Spinner from '@/components/misc/Spinner.vue'

type FilterModeType = 'all' | 'transfer' | 'export_import' | 'stake'
type ModeKeyType = 'all' | 'transfer' | 'swap' | 'stake'

//@ts-ignore
import VirtualList from 'vue-virtual-scroll-list'
import { DcommNetwork } from '@/js/DcommNetwork'
import ExportCsvModal from '@/components/modals/ExportCsvModal.vue'
import ExportDcmCsvModal from '@/components/modals/ExportDcmCsvModal.vue'

const PAGE_LIMIT = 100

const YEAR_MIN = 2022
const MONTH_MIN = 1

@Component({
    name: 'activity',
    components: {
        ExportDcmCsvModal,
        ExportCsvModal,
        Spinner,
        TxRow,
        RadioButtons,
        VirtualList,
    },
})
export default class Activity extends Vue {
    mode: ModeKeyType = 'all'
    modes = [
        this.$t('activity.mode1'),
        this.$t('activity.mode2'),
        this.$t('activity.mode3'),
        this.$t('activity.mode4'),
    ]
    modeKey: ModeKeyType[] = ['all', 'transfer', 'swap', 'stake']
    isLoading = false
    pageNow = 0
    RowComponent = TxRow

    monthNow = 0
    yearNow = 0

    listH = 100

    $refs!: {
        csv_modal: ExportCsvModal
        dcm_csv_modal: ExportDcmCsvModal
    }

    openCsvModal() {
        this.$refs.csv_modal.open()
    }

    openDcmCsvModal() {
        this.$refs.dcm_csv_modal.open()
    }

    get showList(): boolean {
        if (this.isUpdatingAll || this.isLoading) return false
        return true
    }

    get isUpdatingAll(): boolean {
        return this.$store.state.History.isUpdatingAll
    }

    get isNextPage() {
        let now = new Date()
        if (this.yearNow < now.getFullYear()) return true
        if (this.monthNow < now.getMonth()) return true
        return false
    }

    get isPrevPage() {
        // if (this.yearNow  now.getFullYear()) return true
        if (this.monthNow === MONTH_MIN && this.yearNow === YEAR_MIN) return false
        return true
    }

    get monthNowName() {
        return this.$t(`activity.months.${this.monthNow}`)
    }

    get hasExplorer() {
        let network: DcommNetwork | null = this.$store.state.Network.selectedNetwork
        if (!network?.explorerUrl) {
            return false
        }
        return true
    }

    mounted() {
        this.updateHistory()

        let now = new Date()
        this.yearNow = now.getFullYear()
        this.monthNow = now.getMonth()
        this.scrollToTop()
        this.setScrollHeight()
    }
    deleted() {}

    updateHistory() {
        this.$store.dispatch('History/updateAllTransactionHistory')
    }

    get monthGroups(): any {
        let res: any = {}
        let txs = this.txs

        for (var i = 0; i < txs.length; i++) {
            let tx = txs[i]
            let date = new Date(tx.timestamp)
            // let mom = moment(tx.timestamp)
            let month = date.getMonth()
            let year = date.getFullYear()
            let key = `${month}/${year}`
            if (res[key]) {
                res[key].push(tx)
            } else {
                res[key] = [tx]
            }
        }
        return res
    }

    get allTxs(): ITransactionData[] {
        return this.$store.state.History.allTransactions
    }

    get txs(): ITransactionData[] {
        let txs
        switch (this.mode) {
            case 'transfer':
                txs = this.txsTransfer
                break
            case 'swap':
                txs = this.txsSwap
                break
            case 'stake':
                txs = this.txsStake
                break
            default:
                txs = this.allTxs
                break
        }

        let filtered = txs.filter((tx) => {
            let date = new Date(tx.timestamp)

            if (date.getMonth() === this.monthNow && date.getFullYear() === this.yearNow) {
                return true
            }
            return false
        })
        return filtered
    }

    get txsProcessed(): ITransactionDataProcessed[] {
        let txs = this.txs

        let res = txs.map((tx, index) => {
            let showMonth = false
            let showDay = false

            if (index === 0) {
                showMonth = true
                showDay = true
            } else {
                let txBefore = txs[index - 1]

                let date = new Date(tx.timestamp)
                let dateBefore = new Date(txBefore.timestamp)

                if (dateBefore.getMonth() !== date.getMonth()) {
                    showMonth = true
                    showDay = true
                } else if (dateBefore.getDay() !== date.getDay()) {
                    showDay = true
                }
            }

            return {
                ...tx,
                isMonthChange: showMonth,
                isDayChange: showDay,
            }
        })
        return res
    }

    get pageAmount(): number {
        return Math.floor(this.txs.length / PAGE_LIMIT)
    }

    prevPage() {
        if (this.monthNow === 0) {
            this.yearNow = this.yearNow - 1
            this.monthNow = 11
        } else {
            this.monthNow = this.monthNow - 1
        }
        this.scrollToTop()
        this.setScrollHeight()
    }

    nextPage() {
        if (this.monthNow === 11) {
            this.yearNow = this.yearNow + 1
            this.monthNow = 0
        } else {
            this.monthNow = this.monthNow + 1
        }
        this.scrollToTop()
        this.setScrollHeight()
    }

    get txsTransfer(): ITransactionData[] {
        let txs: ITransactionData[] = this.allTxs
        let transferTypes: TransactionType[] = ['base', 'create_asset', 'operation']

        return txs.filter((tx) => {
            let txType = tx.type
            if (transferTypes.includes(txType)) return true

            return false
        })
    }

    get txsSwap(): ITransactionData[] {
        let txs: ITransactionData[] = this.allTxs
        let exportTypes: TransactionType[] = ['import', 'export', 'athvm_import', 'athvm_export']

        return txs.filter((tx) => {
            let txType = tx.type
            if (exportTypes.includes(txType)) return true
            return false
        })
    }

    get txsStake(): ITransactionData[] {
        let txs: ITransactionData[] = this.allTxs
        let stakeTypes: TransactionType[] = ['add_validator', 'add_delegator']

        return txs.filter((tx) => {
            let txType = tx.type
            if (stakeTypes.includes(txType)) return true
            return false
        })
    }

    scrollToTop() {
        //@ts-ignore
        this.$refs.vlist.scrollToIndex(0)
    }
    // The virtual scroll needs to be given a height in pixels
    setScrollHeight() {
        //@ts-ignore
        let h = this.$refs.list.clientHeight
        this.listH = h
    }
}
