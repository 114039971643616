





































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as bip39 from 'bip39'
import MnemonicPasswordInput from '@/components/misc/MnemonicPasswordInput.vue'
import { CypherAES } from '@/js/CypherAES'
import CreateAccount from '@/components/CreateWalletWorkflow/CreateAccount.vue'

@Component({
    components: {
        MnemonicPasswordInput,
        CreateAccount,
    },
})
export default class Mnemonic extends Vue {
    phrase: CypherAES | undefined = undefined
    isLoading: boolean = false
    err: string = ''
    canSubmit = false
    canCreateAccount: boolean = false

    beforeDestroy() {
        this.phrase = undefined
    }

    onPhraseIn(val: string) {
        this.phrase = new CypherAES(val)
        this.formCheck()
    }

    complete() {
        let phrase = this.phrase?.getValue()

        if (!phrase) {
            return
        }

        let words = phrase.split(' ')

        // not a valid key phrase
        if (words.length !== 24) {
            this.err = `${this.$t('access.mnemonic.error')}`
            return
        }

        let isValid = bip39.validateMnemonic(phrase)
        if (!isValid) {
            this.err = 'Invalid mnemonic phrase.'
            return
        }
        this.canCreateAccount = !this.canCreateAccount
    }

    errCheck() {
        let phrase = this.phrase?.getValue()

        if (!phrase) {
            return
        }

        let words = phrase.split(' ')

        // not a valid key phrase
        if (words.length !== 24) {
            this.err = `${this.$t('access.mnemonic.error')}`
            return false
        }

        let isValid = bip39.validateMnemonic(phrase)
        if (!isValid) {
            this.err = 'Invalid mnemonic phrase.'
            return false
        }

        return true
    }

    getWordCount() {
        const phrase = this.phrase?.getValue() || ''
        return phrase.trim().split(' ').length
    }

    formCheck() {
        if (this.getWordCount() !== 24) {
            this.canSubmit = false
            return
        }
        this.canSubmit = true
    }

    async access() {
        const phrase = (this.phrase?.getValue() || '').trim()

        this.isLoading = true

        if (!this.errCheck()) {
            this.isLoading = false
            return
        }

        setTimeout(async () => {
            try {
                await this.$store.dispatch('accessWallet', phrase)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
                this.err = `${this.$t('access.mnemonic.error')}`
            }
        }, 500)
    }
}
