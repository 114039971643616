import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getAddressHistory, getEVMTransaction } from '@/explorer_api'
import moment from 'moment'

import { EvmTransaction, HistoryState, ITransactionData } from '@/store/modules/history/types'
import { dvm, athChain } from '@/DCOMM'
import { filterDuplicateTransactions } from '@/helpers/history_helper'

const history_module: Module<HistoryState, RootState> = {
    namespaced: true,
    state: {
        isUpdating: false,
        isUpdatingAll: false,
        transactions: [], // Used for the history sidepanel txs
        allTransactions: [], // Used for activity tab txs, paginates
        cTransactions: [],
    },
    mutations: {
        clear(state) {
            state.transactions = []
            state.allTransactions = []
            state.cTransactions = []
        },
    },
    actions: {
        async updateTransactionHistory({ state, rootState, rootGetters, dispatch }) {
            const wallet = rootState.activeWallet
            if (!wallet) return

            // If wallet is still loading delay
            // @ts-ignore
            const network = rootState.Network.selectedNetwork

            if (!wallet.isInit) {
                setTimeout(() => {
                    dispatch('updateTransactionHistory')
                    dispatch('updateEvmTransaction')
                }, 500)
                return false
            }

            // can't update if there is no explorer or no wallet
            if (!network || !network.explorerUrl || rootState.address === null) {
                return false
            }

            state.isUpdating = true

            const dvmAddrs: string[] = wallet.getAllAddressesX()
            const athvmAddrs: string[] = wallet.getAllAddressesP()
            // this shouldnt ever happen, but to avoid getting every transaction...
            if (dvmAddrs.length === 0) {
                state.isUpdating = false
                return
            }
            const limit = 20
            const txs = await getAddressHistory(dvmAddrs, limit, dvm.getBlockchainID())
            const txsP = await getAddressHistory(athvmAddrs, limit, athChain.getBlockchainID())
            const transactions = txs
                .concat(txsP)
                .sort((x, y) => (moment(x.timestamp).isBefore(moment(y.timestamp)) ? 1 : -1))
            state.transactions = transactions
            state.isUpdating = false
        },
        async updateEvmTransaction({ state, rootState, dispatch }) {
            const wallet = rootState.activeWallet
            if (!wallet) return
            state.isUpdating = true
            const cAddress = `0x${wallet.getEvmAddress()}`
            const txCApi = await getEVMTransaction({ address: cAddress, limit: 20 })
            const txC: EvmTransaction[] = txCApi.Transactions
            state.cTransactions = txC
            state.isUpdating = false
        },
        async updateAllTransactionHistory({ state, rootState, rootGetters, dispatch }) {
            const wallet = rootState.activeWallet
            if (!wallet) return

            // If wallet is still loading delay
            // @ts-ignore
            const network = rootState.Network.selectedNetwork

            if (!wallet.isInit) {
                setTimeout(() => {
                    dispatch('updateAllTransactionHistory')
                }, 500)
                return false
            }

            // can't update if there is no explorer or no wallet
            if (!network.explorerUrl || rootState.address === null) {
                return false
            }

            state.isUpdatingAll = true

            const dvmAddrs: string[] = wallet.getAllAddressesX()
            const athvmAddrs: string[] = wallet.getAllAddressesP()

            // this shouldnt ever happen, but to avoid getting every transaction...
            if (dvmAddrs.length === 0) {
                state.isUpdatingAll = false
                return
            }

            const limit = 0

            const txsX = await getAddressHistory(dvmAddrs, limit, dvm.getBlockchainID())
            const txsP = await getAddressHistory(athvmAddrs, limit, athChain.getBlockchainID())

            const txsXFiltered = filterDuplicateTransactions(txsX)
            const txsPFiltered = filterDuplicateTransactions(txsP)

            const transactions = txsXFiltered
                .concat(txsPFiltered)
                .sort((x, y) => (moment(x.timestamp).isBefore(moment(y.timestamp)) ? 1 : -1))

            state.allTransactions = transactions
            state.isUpdatingAll = false
        },
    },
    getters: {
        stakingTxs(state) {
            return state.allTransactions.filter((tx) => {
                const types = ['add_validator', 'add_delegator']
                if (types.includes(tx.type)) {
                    return true
                }
                return false
            })
        },
    },
}

export default history_module
