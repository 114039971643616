

























































































import { Vue, Component } from 'vue-property-decorator'
import { ImportKeyfileInput } from '@/store/types'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'
import { privateToAddress } from 'ethereumjs-util'
import { bintools } from '@/DCOMM'
import { Buffer } from '@dcomm-tech/dcomm-js'
import { strip0x } from '@dcomm-tech/wallet-sdk'
import CreateAccount from '@/components/CreateWalletWorkflow/CreateAccount.vue'

@Component({
    components: {
        CreateAccount,
    },
})
export default class PrivateKey extends Vue {
    privatekey: string = ''
    isPasswordVisible: boolean = false
    isLoading: boolean = false
    error: string = ''
    inputType: boolean = true
    canCreateAccount: boolean = false
    async access() {
        if (!this.canSubmit || this.isLoading) return
        let parent = this
        this.error = ''
        this.isLoading = true
        let key = strip0x(this.privatekey)

        try {
            let res = await this.$store.dispatch('accessWalletSingleton', key)
            this.onsuccess()
        } catch (e) {
            this.onerror('Invalid Private Key.')
        }
    }

    onToggleVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible
    }

    onsuccess() {
        this.isLoading = false
        this.privatekey = ''
    }
    onerror(e: any) {
        this.error = e
        this.privatekey = ''
        this.isLoading = false
    }
    async complete() {
        try {
            let key = strip0x(this.privatekey)
            const keyBuf = Buffer.from(key, 'hex')
            // @ts-ignore
            privateToAddress(keyBuf)
            this.canCreateAccount = !this.canCreateAccount
        } catch (e) {
            this.onerror('Invalid Private Key.')
        }
    }
    onShow() {
        this.inputType = !this.inputType
    }
    get canSubmit(): boolean {
        if (!this.privatekey) {
            return false
        }
        return true
    }
}
