import { render, staticRenderFns } from "./CrossChain.vue?vue&type=template&id=a12b18d2&scoped=true&"
import script from "./CrossChain.vue?vue&type=script&lang=ts&"
export * from "./CrossChain.vue?vue&type=script&lang=ts&"
import style0 from "./CrossChain.vue?vue&type=style&index=0&id=a12b18d2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a12b18d2",
  null
  
)

export default component.exports